/* tslint:disable */
/* eslint-disable */
/**
 * AnyMenu
 * The AnyMenu API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    imageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    secureImageUrl: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    publicImageId: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    type: ProductTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    readonly: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    subType: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    lang: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    allergenList: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    possibleVariants: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    selectedVariant: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    calories: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    restaurantId: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    quantity: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    restaurant: number;
}


/**
 * @export
 */
export const ProductTypeEnum = {
    Food: 'food',
    Drink: 'drink'
} as const;
export type ProductTypeEnum = typeof ProductTypeEnum[keyof typeof ProductTypeEnum];


/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "imageUrl" in value;
    isInstance = isInstance && "secureImageUrl" in value;
    isInstance = isInstance && "publicImageId" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "readonly" in value;
    isInstance = isInstance && "subType" in value;
    isInstance = isInstance && "lang" in value;
    isInstance = isInstance && "allergenList" in value;
    isInstance = isInstance && "possibleVariants" in value;
    isInstance = isInstance && "selectedVariant" in value;
    isInstance = isInstance && "calories" in value;
    isInstance = isInstance && "restaurantId" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "restaurant" in value;

    return isInstance;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'imageUrl': json['imageUrl'],
        'secureImageUrl': json['secureImageUrl'],
        'publicImageId': json['publicImageId'],
        'type': json['type'],
        'readonly': json['readonly'],
        'subType': json['subType'],
        'lang': json['lang'],
        'allergenList': json['allergenList'],
        'possibleVariants': json['possibleVariants'],
        'selectedVariant': json['selectedVariant'],
        'calories': json['calories'],
        'restaurantId': json['restaurantId'],
        'price': json['price'],
        'quantity': json['quantity'],
        'restaurant': json['restaurant'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'imageUrl': value.imageUrl,
        'secureImageUrl': value.secureImageUrl,
        'publicImageId': value.publicImageId,
        'type': value.type,
        'readonly': value.readonly,
        'subType': value.subType,
        'lang': value.lang,
        'allergenList': value.allergenList,
        'possibleVariants': value.possibleVariants,
        'selectedVariant': value.selectedVariant,
        'calories': value.calories,
        'restaurantId': value.restaurantId,
        'price': value.price,
        'quantity': value.quantity,
        'restaurant': value.restaurant,
    };
}

