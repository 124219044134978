// eslint-disable
export default {
  addons: 'Addons',
  about: 'About',
  currency: 'MKD',
  allergens: 'Allergens',
  addToCart: 'Add To Cart',
  addedToCart: 'Added To Cart',
  menu: 'Menu',
  menuTitle: 'Tap to order!',
  menuTitleDescription: 'Order now and a waiter will bring it shortly.',
  newNotification: 'New notification!',
  in: 'in',
  restaurant: 'Restaurant',
  name: 'Name',
  status: 'Status',
  dialogContentText:
    'Please note: once the receipt is created, the order will no longer be accessible',
  dialogTitle: 'Confirmation needed before order is closed',
  food: 'Food',
  'food.kotur': 'Miss Ston',
  submit: 'Submit',
  description: 'Description',
  table: 'Table',
  drinks: 'Drinks',
  drink: 'Drink',
  'drink.kotur': 'Kotur',
  active: 'Active',
  reset: 'Reset',
  type: 'Type',
  edit: 'Edit',
  cancel: 'Cancel',
  orderNow: 'My order',
  orderStateCancelled: 'cancelled',
  orderStateDelivered: 'delivered',
  orderStateDeliveredSelfService: 'ready',
  orderStateCreated: 'created',
  orderStatePreparation: 'preparation',
  quantity: 'Quantity',
  price: 'Price',
  subType: 'Subtype',
  calories: 'Calories',
  save: 'Save',
  accept: 'Accept',
  logOut: 'Log Out',
  delete: 'Delete',
  language: 'Language',
  inactive: 'Inactive',
  orderId: 'Order ID',
  createdAt: 'Created at',
  orders: 'Orders',
  noOrdersAtTheMoment: 'No Orders at the moment :(',
  readonlyProduct: 'Call a waiter to order this item',
  adminProductStatusViewOnly: 'View-only',
  adminProductStatusesMessage:
    'Watch out, this will prevent your customers from ordering any of the products?',
  adminAddNewVariant: 'Add new variant',
  adminConfirmCancellingOrder: 'Are you sure you wanna cancel this order?',
  adminRestaurantInfoEditPageCloseRestaurant: 'Close restaurant',
  adminRestaurantInfoEditPageOpenRestaurant: 'Open restaurant',
  adminRestaurantClosedTitle: 'The restaurant is closed!',
  adminRestaurantClosedDescription: 'Open the restaurant to accept orders',
  // TODO: translate
  adminRestaurantIsSelfService: 'This restaurant is self-service',
  adminWaiterCalled: 'Waiter Called!',
  adminReceiptCalled: 'Receipt Called!',
  adminAcceptedClientCallNotification: "You accepted the client's call",
  adminAcceptedChangeOrderStatusNotification: 'You changed the order status to accepted',
  adminManageUserCreated: 'User Created!',
  adminManageUserUpdated: 'User Updated',
  adminUserChangePassword: 'Change Password',
  adminTypeOfUser: 'Type Of User',
  adminUserChangePasswordNewLabel: 'New Password',
  adminUserChangePasswordVerifyLabel: 'Verify Password',
  noActiveTables: 'No active tables found',
  noResults: 'No results found',
  userAskedForReceiptNotification: 'You have asked for a receipt. Your orders are closed!',
  userCalledWaiterCall: 'Waiter has received your request. Hold on until your request is accepted',
  userAcceptedWaiterCall: 'Your waiter call is accepted',
  userWaiterBringReceipt: 'Your waiter will bring the receipt!',
  userOrderAccepted: 'Order No.{{clientsOrderId}} is Accepted',
  userOrderDelivered: 'Order No.{{clientsOrderId}} is Ready!',
  userOrderAcceptedStatus: 'Status: {{clientsOrderStatus}}',
  userTableNumberCode: 'Your Table ({{tableNumber}}) code is:',
  redirectEnterCode: 'Your Table is locked! Please enter the code:',
  redirectOpenWithCode: 'Open table with code!',
  redirectEnterValidCode: 'Please enter a valid code!',
  redirectYourCodeIs: 'Your code is {{code}}',
  redirectOpenTable: 'Open up!',
  restaurantClosed: 'The restaurant is not accepting orders at the moment. Please try again later.',
  alertAgree: 'Agree',
  alertDisagree: 'Disagree',
  cartOrder: 'Order',
  cartItems: 'Cart items',
  cart: 'Cart',
  cartAllOrders: 'All orders ({{tableNumber}})',
  cartClearAllButton: 'Clear All',
  cartNoItems: 'There are no items in the cart',
  cartAdditionalComment: 'Additional Comments for order',
  adminAdditionalComment: 'Comments for order',
  cartTotal: 'Total',
  cartCallWaiterButton: 'Call Waiter',
  cartOrderButton: 'Order',
  cartCloseButton: 'Close',
  receiptTotalAmount: 'Total amount for payment: {{totalAmount}}',
  tableOrders: 'Orders',
  tableOrdersTotalAmount: 'Total amount: {{totalAmount}}',
  tableOrdersAskForReceipt: 'Receipt',
  tableOrdersOrderItems: 'Order items',
  tableOrdersNoItems: 'There are no items in the cart',
  landingPageTitle: 'Welcome to AnyMenu',
  landingPageSubTitle: 'Select a restaurant to begin.',
  landingPageRestaurants: 'Select a restaurant to begin.',
  landingPageErrorLoadRestaurants: 'There was an error retrieving restaurants.',
  landingPageNoRestaurantsAvailable: 'There are no restaurants currently available.',
  errorPageDesc: 'Sorry, we could not find that page.',
  errorPageButtonBack: 'Go Back',
  loginPageTitle: 'Welcome Back',
  adminSiteNavHome: 'Home',
  adminSiteNavRestaurant: 'Restaurant',
  adminSiteNavProducts: 'Products',
  adminSiteNavOrders: 'Orders',
  adminSiteNavManageUsers: 'Manage Users',
  adminSiteNavManageApiKeys: 'Manage API Keys',
  adminApiKeyCreated: 'A new API key has been created',
  adminManageUserDeleted: 'User Deleted!',
  landingPageEnterTitle: 'Han*gry!',
  typeOfUser: 'Type: ',
  adminSiteNavManageRestaurant: 'Manage Restaurants',
  adminManageRestaurantPageTitle: 'Manage Restaurants',
  adminManageRestaurantTitle: 'Restaurants',
  adminMarkAsDelivered: 'Mark as delivered',
  adminPushNotificationOrderTitle: 'New order received!',
  adminPushNotificationOrderBody: '`Table {{tableNumber}} has a new order with ID: {{order_id}}',
  adminPushNotificationCallWaiterTitle: 'Waiter call!',
  adminPushNotificationCallWaiterBody: 'Table {{tableNumber}} called for waiter',
  adminPushNotificationCallReceiptTitle: 'Receipt request!',
  adminPushNotificationCallReceiptBody: 'Table {{tableNumber}} has asked for receipt',
  adminNewOrdersBadge: '{{count}} New Orders',
  adminFieldsManageRestaurantEmail: 'Email',
  adminFieldsManageRestaurantAddress: 'Address',
  adminFieldsManageRestaurantPhone: 'Phone',
  adminFieldsManageRestaurantWifi: 'WiFi',
  adminFieldsManageRestaurantInstagram: 'Instagram',
  adminManageRestaurantEdit: 'Restaurant Updated',
  adminManageRestaurantCreate: 'Restaurant Created!',
  adminManageRestaurantDeleted: 'Restaurant Deleted!',
  adminSelectresourant: 'Select Restaurant',
  adminTableFiltersButton: 'Create',
  adminCreateQRCodeShowCodeButton: 'Show Tables QR',
  adminCreateQRCodeDialogTitle: 'Tables QR Info',
  adminCreateQRCodeDownload: 'Download for Print',
  adminSimulateQRCodeLink: 'Simulate scan (opens in a new tab)',
  adminRestaurantInfoEditButton: 'Edit',
  adminRestaurantInfoEditTitle: 'Edit Info',
  adminRestaurantInfoEditTablesButton: 'Edit Tables',
  adminRestaurantInfoEditTablesTitle: 'Edit Info',
  adminRestaurantInfoEditTablesInfo: 'Table Number',
  adminRestaurantInfoEditTablesCode: 'Current Code',
  adminRestaurantInfoEditPageTitle: 'Restaurant Info',
  adminManageUsersPageTitle: 'Manage Users',
  adminRestaurantManagement: 'Restaurant Management',
  adminDashboardTitle: 'Table Overview',
  adminTableOverviewTitle: 'Overview for table',
  adminTableOverviewCloseTableBringReceipt: 'Close table & bring Receipt',
  adminTableOverviewAcceptOrdersButton: 'Accept all orders',
  adminTableOverviewMarkAllButton: 'Mark all as delivered',
  adminTableOverviewAllOrdersAcceptedNotification: 'All orders have been accepted!',
  adminTableOverviewAllOrdersMarkedAsDeliveredNotification:
    'You have marked all orders as delivered!',
  adminTableOverviewAcceptReceiptButton: 'Accept Receipt Called',
  adminTableOverviewAcceptOrderButton: 'Accept Order',
  adminTableOverviewCancelOrderButton: 'Cancel Order',
  adminTableOverviewSaveStatustoOrder: 'Save Status to order',
  adminTableOverviewReceiptCalled:
    'Table Receipt Called. If you accept Receipt Called, all orders will be closed for this table!',
  adminTableOverviewTableNumber: 'Table Number'
};
