import { Backdrop, Box, Button, CircularProgress, MenuItem, Select, TextField, Typography, styled } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getRestaurantsAsync, selectActiveRestaurantInfo, setActiveRestaurantId } from '../restaurantSlice';
import { reserveTable, updateRestaurantInfo } from '../api/restaurantsApi';
import { useTranslation } from 'react-i18next';
import { anymenuTheme } from '@root/ui/theming/theme';
import { generateCodeId } from "@root/utils/code"


const StyledBoxWrapper = styled(Box)(() => ({
  position: "relative",
  backgroundColor: 'white'
}));

const StyledLockWrapper = styled(Box)(() => ({
  position: "absolute",
  top: '10%',
  borderRadius: `${anymenuTheme.th.borderRadius}`,
  background: '#ffffff',
  minWidth: '30%',
  width: '90%',
  height: '250px',
  left: '5%',
}));

const StyledBluryBG = styled(Box)(() => ({
  background: `url(https://res.cloudinary.com/hy4epz6jm/image/upload/v1678537262/cld-sample-4.jpg) no-repeat center center fixed`,
  filter: 'blur(8px)',
  height: '100%'
}));

const StyledTypographyHeader = styled(Typography)(() => ({
  background: '#664399',
  width: '100%',
  borderRadius: `${anymenuTheme.th.borderRadius} ${anymenuTheme.th.borderRadius} 0px 0px`,
  padding: '2%',
  textAlign: 'center',
  color: '#fff',
}));

const StyledBoxCentral = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '50%',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginTop: '30px'
}));

const RestaurantRedirect = (): JSX.Element => {
  const { t } = useTranslation();
  const activeRestaurant = useAppSelector(selectActiveRestaurantInfo);
  const [query] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const [inputCode, setInputCode] = useState('');
  const [tableCodeActive, setTableCodeActive] = useState(false);
  const [isFromLanding, setisFromLanding] = useState(false);
  const [isFromLandingTableNumber, setisFromLandingTableNumber] = useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const tableNumber = Number(query.get('tableNumber'));
  const restaurantId = Number(query.get('restaurant'));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (restaurantId) {
      dispatch(setActiveRestaurantId(+restaurantId));
    }
  }, [restaurantId]);

  useEffect(() => {
    if (tableNumber) {
      const islanding = (+tableNumber == -1);
      setisFromLanding(islanding);
    }
  }, [tableNumber]);

  useEffect(() => {
    if (!activeRestaurant) {
      dispatch(getRestaurantsAsync());
    } else {
      setCode(generateCodeId(5));
      const foundTable = (activeRestaurant.activeTables as any[]).find(item => item.table == tableNumber);
      if (foundTable) {
        if (!foundTable.active) {
          setOpen(true);
        }
      } else {
        setOpen(true);
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [activeRestaurant, dispatch]);

  const updateActiveTables = () => {
    reserveTable(restaurantId, { tableNumber, code }).then(() => {
      navigate(`/restaurants/${restaurantId}?tableNumber=${tableNumber}&code=${code}`);
    });
  }

  return (
    <StyledBoxWrapper>
      <StyledBluryBG />
      {activeRestaurant?.isclosed ? (
          <Backdrop
          sx={{ zIndex: 999 }}
          open={true}
          onClick={() => {}}
        >
          <Typography variant="h5" sx={{ color: '#fff', p:2, textAlign: "center" }}>
            {t('restaurantClosed')}
          </Typography>
          
        </Backdrop>
        ) : (
          <StyledLockWrapper>
          {loading ? (
            <CircularProgress sx={{
              margin: '0 auto',
              marginTop: '50px',
              display: 'block'
            }} />
          ) : !open ? (
              <>
                <StyledTypographyHeader variant="h6">
                  {t('redirectEnterCode')}
                </StyledTypographyHeader>
                <StyledBoxCentral>
                  <TextField
                    sx={{
                      width: '100%',
                      margin: '0px 25px',
                    }}
                    type='text'
                    label="code"
                    name="code"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target && event.target.value) {
                        setInputCode(event.target.value);
                      }
                    }
                    }
                  />
                  <Button variant="contained" sx={{ minWidth: '150px', m: "15px" }}
                    onClick={() => {
                      const foundTable = activeRestaurant && (activeRestaurant.activeTables as any[]).find(item => item.table == tableNumber);
                      if (inputCode !== '' && foundTable) {
                        if (foundTable.code == inputCode) {
                          setTableCodeActive(true);
                          navigate(`/restaurants/${restaurantId}?tableNumber=${tableNumber}&code=${foundTable.code}`);
                        } else {
                          setTableCodeActive(false);
                        }
                      }
                    }}>
                    {t('redirectOpenWithCode')}
                  </Button>
                  {!tableCodeActive && <Typography sx={{ m: '15px', color: 'red' }}>{t('redirectEnterValidCode')}</Typography>}
                </StyledBoxCentral>
              </>
            ) : (<>
              <StyledTypographyHeader variant="h6">
                {isFromLanding ? (<Box>
                  Please select table
                </Box>) : (
                  <>
                    {t('redirectYourCodeIs', { code: code })}
                  </>
                )}
              </StyledTypographyHeader>
              <StyledBoxCentral>
                {isFromLanding ? (<Box>
                  <Select
                    sx={{
                      background: 'white',
                      margin: '0px 15px 0px 0px',
                      color: 'black'
                    }}
                    value={isFromLandingTableNumber}
                    onChange={({ target }) => {
                      setisFromLandingTableNumber(+target.value)
                    }}
                  >
                    {[...Array(activeRestaurant ? activeRestaurant.numberOfTables : 0).keys()].map(restaurant => (
                      restaurant > 0 && <MenuItem value={restaurant}>Table {restaurant}</MenuItem>
                    ))}
                  </Select>
                  <Button variant="contained" sx={{ minWidth: '150px' }} onClick={() => {
                    navigate({
                      pathname: `/restaurants/redirect`,
                      search: `?tableNumber=${isFromLandingTableNumber}&restaurant=${activeRestaurant?.id}`
                    });
                    location.reload();
                  }}>
                    Go to table {isFromLandingTableNumber}
                  </Button>
                </Box>) : (
                  <Button variant="contained" sx={{ minWidth: '150px' }} onClick={updateActiveTables}>
                    {t('redirectOpenTable')}
                  </Button>
                )}
              </StyledBoxCentral>
            </>)
            }
        </StyledLockWrapper>
        )
      }
    </StyledBoxWrapper>
  );
};

export default RestaurantRedirect;
