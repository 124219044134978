import { Language as LanguageIcon } from '@mui/icons-material';
import { Box, Fab, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import al from '../../assets/al.png';
import cro from '../../assets/cro.png';
import en from '../../assets/en.png';
import mk from '../../assets/mk.png';
import { LanguagesInApp } from '../../utils/models/Languages';

export function Lang() {
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  return (
    <Box
      sx={{
        width: '120px',
        padding: '0 33px',
        marginRight: '15px',
        backgroundColor: 'white',
        '&:active': {
          background: 'white'
        },
        '&:focus': {
          backgroundColor: 'white'
        },
        '&:visited': {
          background: 'white'
        }
      }}>
      <Select
        variant="filled"
        disableUnderline={true}
        sx={{
          backgroundColor: 'white'
        }}
        MenuProps={{
          sx: {
            '&& .Mui-selected': {
              backgroundColor: '#FF5722'
            }
          }
        }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        displayEmpty
        renderValue={() => {
          return (
            <Fab color="primary">
              <LanguageIcon />
            </Fab>
          );
        }}
        value={currentLang}
        inputProps={{ IconComponent: () => null }}
        label="Status"
        onChange={({ target }) => {
          i18n.changeLanguage(target.value);
        }}>
        <MenuItem value={LanguagesInApp.EN}>
          EN <img style={{ width: '21px', margin: '0px 10px' }} src={en} />
        </MenuItem>
        <MenuItem value={LanguagesInApp.MK}>
          MK <img style={{ width: '21px', margin: '0px 10px' }} src={mk} />
        </MenuItem>
        <MenuItem value={LanguagesInApp.AL}>
          AL <img style={{ width: '21px', margin: '0px 10px' }} src={al} />
        </MenuItem>
        <MenuItem value={LanguagesInApp.CRO}>
          CRO <img style={{ width: '21px', margin: '0px 10px' }} src={cro} />
        </MenuItem>
      </Select>
    </Box>
  );
}
