// eslint-disable
export default {
  addons: 'Dodatoci',
  about: 'Rreth',
  calories: 'Kalori',
  currency: 'MKD',
  allergens: 'Alergjenë',
  addToCart: 'Shto në Shportë',
  addedToCart: 'Shtuar në Shportë',
  menu: 'Menu',
  // TODO: Translate
  menuTitle: 'Tap to order!',
  // TODO: Translate
  menuTitleDescription: '',
  in: 'në',
  name: 'Emër',
  status: 'Status',
  dialogContentText:
    'Ju lutemi vini re: sapo fatura të krijohet, porosia nuk do të jetë më e arritshme',
  dialogTitle: 'Konfirmim i nevojshëm përpara mbylljes së porosisë',
  food: 'Ushqim',
  submit: 'Dërgo',
  // TODO: translate
  orderNow: 'Order now',
  // TODO: Translate
  orderStateCancelled: 'cancelled',
  // TODO: Translate
  orderStateDelivered: 'delivered',
  // TODO: Translate
  orderStateDeliveredSelfService: 'ready',
  // TODO: Translate
  orderStateCreated: 'created',
  // TODO: Translate
  orderStatePreparation: 'preparation',
  description: 'Përshkrim',
  table: 'Tavolinë',
  drinks: 'Pije',
  drink: 'Pije',
  active: 'Aktiv',
  // TODO: Translate
  reset: 'Reset',
  type: 'Lloji',
  edit: 'Ndrysho',
  cancel: 'Anullo',
  quantity: 'Sasia',
  price: 'Çmimi',
  subType: 'Nën-lloji',
  save: 'Ruaj',
  accept: 'Prano',
  logOut: "Ç'identifikohu",
  delete: 'Fshi',
  language: 'Gjuha',
  inactive: 'joaktiv',
  orderId: 'ID e Porosisë',
  createdAt: 'Krijuar më',
  orders: 'Porosi',
  noOrdersAtTheMoment: 'Momentalisht nuk ka porosi :(',
  // TODO: translate
  readonlyProduct: 'Call a waiter to order this item',
  // TODO: Translate
  adminProductStatusViewOnly: 'View-only',
  // TODO: Translate
  adminProductStatusesMessage:
    'Watch out, this will prevent your customers from ordering any of the products?',
  // TODO: Translate
  adminAddNewVariant: 'Add new variant',
  // TODO: Translate
  adminConfirmCancellingOrder: 'Are you sure you wanna cancel this order?',
  adminWaiterCalled: 'Thirrur kamarieri!',
  adminReceiptCalled: 'Thirrur fatura!',
  adminAcceptedClientCallNotification: 'Ju pranuat thirrjen e klientit',
  adminAcceptedChangeOrderStatusNotification: 'Ju ndryshuat statusin e porosisë në pranuar',
  // TODO: translate
  adminRestaurantInfoEditPageCloseRestaurant: 'Close restaurant',
  // TODO: translate
  adminRestaurantInfoEditPageOpenRestaurant: 'Open restaurant',
  // TODO: translate
  adminRestaurantClosedTitle: 'The restaurant is closed!',
  // TODO: translate
  adminRestaurantClosedDescription: 'Open the restaurant to accept orders',
  // TODO: translate
  adminRestaurantIsSelfService: 'This restaurant is self-service',
  noActiveTables: 'No active tables found',
  noResults: 'Nuk u gjetën rezultate',
  userAskedForReceiptNotification: 'Ju keni kërkuar një faturë. Porositë tuaja janë mbyllur!',
  // TODO: translate
  userCalledWaiterCall: 'Waiter has received your request. Hold on until your request is accepted',
  userAcceptedWaiterCall: 'Thirrja juaj për kamarierin është pranuar',
  userWaiterBringReceipt: 'Kamarieri juaj do të sjellë faturën!',
  userOrderAccepted: 'Porosia Nr.{{clientsOrderId}} është pranuar',
  // TODO: translate
  userOrderDelivered: 'Order No.{{clientsOrderId}} is Ready!',
  userOrderAcceptedStatus: 'Statusi: {{clientsOrderStatus}}',
  userTableNumberCode: 'Kodi i Tavolinës Tuaj ({{tableNumber}}) është:',
  redirectEnterCode: 'Tavolina juaj është e mbyllur! Ju lutemi vendosni kodin:',
  redirectOpenWithCode: 'Hapni tavolinën me kod!',
  redirectEnterValidCode: 'Ju lutemi vendosni një kod të vlefshëm!',
  redirectYourCodeIs: 'Kodi juaj është {{code}}',
  redirectOpenTable: 'Hapuni!',
  // TODO: Translate
  restaurantClosed: 'The restaurant is not accepting orders at the moment. Please try again later.',
  alertAgree: 'Pranoj',
  alertDisagree: 'Nuk pranoj',
  // TODO: Translate
  cart: 'Cart',
  // TODO: Translate
  cartAllOrders: 'All orders ({{tableNumber}})',
  cartOrder: 'Porosi',
  cartItems: 'Artikujt në Shportë',
  cartClearAllButton: 'Pastro të Gjitha',
  cartNoItems: 'Nuk ka artikuj në shportë',
  cartAdditionalComment: 'Additional Comments for order',
  adminAdditionalComment: 'Comments for order',
  cartTotal: 'Totali',
  cartCallWaiterButton: 'Thirr Kamarierin',
  cartOrderButton: 'Porosi',
  cartCloseButton: 'Mbyll',
  receiptTotalAmount: 'Shuma totale për pagesë: {{totalAmount}}',
  tableOrders: 'Porositë',
  tableOrdersTotalAmount: 'Shuma totale: {{totalAmount}}',
  tableOrdersAskForReceipt: 'Kërko Faturë',
  tableOrdersOrderItems: 'Artikujt e Porosisë',
  tableOrdersNoItems: 'Nuk ka artikuj në shportë',
  landingPageTitle: 'Mirë se vini në AnyMenu',
  landingPageSubTitle: 'Zgjidhni një restorant për të filluar.',
  landingPageRestaurants: 'Zgjidhni një restorant për të filluar.',
  landingPageErrorLoadRestaurants:
    'Ka ndodhur një gabim gjatë marrjes së të dhënave të restoranteve.',
  landingPageNoRestaurantsAvailable: 'Aktualisht nuk ka restorante në dispozicion.',
  errorPageDesc: 'Na vjen keq, nuk mund të gjejmë faqen e kërkuar.',
  errorPageButtonBack: 'Kthehu Mbrapa',
  loginPageTitle: 'Mirë se vini përsëri',
  adminSiteNavHome: 'Ballina',
  adminSiteNavRestaurant: 'Restorant',
  adminSiteNavProducts: 'Produkte',
  adminSiteNavOrders: 'Porosi',
  adminSiteNavManageUsers: 'Menaxho Përdoruesit',
  // TODO: translate
  adminSiteNavManageApiKeys: 'Manage API Keys',
  // TODO: translate
  adminApiKeyCreated: 'A new API key has been created',
  adminManageUserCreated: 'Përdoruesi u Krijua!',
  adminManageUserDeleted: 'Përdoruesi u Fshi!',
  adminManageUserUpdated: 'Përdoruesi u Përditësua',
  adminTypeOfUser: 'Lloji i Përdoruesit',
  adminUserChangePassword: 'Ndrysho Fjalëkalimin',
  adminUserChangePasswordNewLabel: 'Fjalëkalimi i Ri',
  adminUserChangePasswordVerifyLabel: 'Verifiko Fjalëkalimin',
  landingPageEnterTitle: 'Hapu!',
  typeOfUser: 'Lloji: ',
  adminSiteNavManageRestaurant: 'Menaxho Restorantet',
  // TODO: Translate
  adminPushNotificationOrderTitle: 'New order received!',
  // TODO: Translate
  adminPushNotificationOrderBody: '`Table {{tableNumber}} has a new order with ID: {{order_id}}',
  // TODO: Translate
  adminPushNotificationCallWaiterTitle: 'Waiter call!',
  // TODO: Translate
  adminPushNotificationCallWaiterBody: 'Table {{tableNumber}} called for waiter',
  // TODO: Translate
  adminPushNotificationCallReceiptTitle: 'Receipt request!',
  // TODO: Translate
  adminPushNotificationCallReceiptBody: 'Table {{tableNumber}} has asked for receipt',
  // TODO translate
  adminNewOrdersBadge: '{{count}} New Orders',
  adminManageRestaurantPageTitle: 'Menaxho Restorantet',
  adminManageRestaurantTitle: 'Restorantet',
  adminFieldsManageRestaurantEmail: 'Email',
  adminFieldsManageRestaurantAddress: 'Adresa',
  adminFieldsManageRestaurantPhone: 'Telefoni',
  adminFieldsManageRestaurantWifi: 'WiFi',
  adminFieldsManageRestaurantInstagram: 'Instagram',
  // TODO: Translate
  adminMarkAsDelivered: 'Mark as delivered',
  adminManageRestaurantEdit: 'Restoranti u Përditësua',
  adminManageRestaurantCreate: 'Restoranti u Krijua!',
  adminManageRestaurantDeleted: 'Restoranti u Fshi!',
  adminSelectresourant: 'Zgjidh Restorantin',
  adminTableFiltersButton: 'Krijo',
  adminCreateQRCodeShowCodeButton: 'Shfaq Kodet e Tavolinave',
  adminCreateQRCodeDialogTitle: 'Informacione për Kodet e Tavolinave',
  adminCreateQRCodeDownload: 'Shkarko për Printim',
  // TODO: Translate
  adminSimulateQRCodeLink: 'Simulate scan (opens in a new tab)',
  adminRestaurantInfoEditButton: 'Ndrysho',
  adminRestaurantInfoEditTitle: 'Ndrysho Informacionet',
  adminRestaurantInfoEditTablesButton: 'Ndrysho Tavolinat',
  adminRestaurantInfoEditTablesTitle: 'Ndrysho Informacionet',
  adminRestaurantInfoEditTablesInfo: 'Numri i Tavolinës',
  adminRestaurantInfoEditTablesCode: 'Kodi Aktual',
  adminRestaurantInfoEditPageTitle: 'Informacione për Restorantin',
  adminManageUsersPageTitle: 'Manage Users',
  adminRestaurantManagement: 'Menaxhimi i Restorantit',
  adminDashboardTitle: 'Përkqitje e Tavolinave',
  adminTableOverviewTitle: 'Përmbledhje për tavolinën',
  adminTableOverviewCloseTableBringReceipt: 'Mbyll tavolinën dhe sjell faturën',
  adminTableOverviewAcceptOrdersButton: 'Prano të gjitha porositë',
  adminTableOverviewMarkAllButton: 'Shëno të gjitha si të dorëzuara',
  adminTableOverviewAllOrdersAcceptedNotification: 'Të gjitha porositë janë pranuar!',
  adminTableOverviewAllOrdersMarkedAsDeliveredNotification:
    'Ju i keni shënuar të gjitha porositë si të dorëzuara!',
  adminTableOverviewAcceptReceiptButton: 'Prano thirrjen për faturë',
  adminTableOverviewAcceptOrderButton: 'Prano Porosinë',
  // TODO: Translate
  adminTableOverviewCancelOrderButton: 'Cancel Order',
  adminTableOverviewSaveStatustoOrder: 'Ruaj Statusin për porosinë',
  adminTableOverviewReceiptCalled:
    'Tavolina është thirrur për faturë. Nëse pranoni thirrjen për faturë, të gjitha porositë do të mbyllen për këtë tavolinë!',
  adminTableOverviewTableNumber: 'Numri i Tavolinës'
};
