import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { createSelector } from 'reselect';

import { Product } from '../../api';
import { RootState } from '../../store';
import { ApiStatus } from '../../utils/models/ApiStatus';
import { getProductsForRestaurant } from './api/productsApi';

interface ProductsState {
  status: ApiStatus;
  items: Product[];
  total: number;
  activeCategory: string;
  productType: string;
}

const initialState: ProductsState = {
  status: ApiStatus.IDLE,
  items: [],
  total: 0,
  activeCategory: 'all',
  productType: 'food'
};

export const getProductsForRestaurantAsync = createAsyncThunk(
  'products/getProductsForRestaurant',
  async ({ restaurantId, lang }: { restaurantId: number; lang: string }) => {
    const response = await getProductsForRestaurant(restaurantId, lang);
    return response;
  }
);

const productsSlice = createSlice({
  name: 'Products Slice',
  initialState,
  reducers: {
    clearError: (state) => {
      state.status = ApiStatus.IDLE;
    },
    setActiveCategory: (state, { payload }: PayloadAction<string>) => {
      state.activeCategory = payload;
    },
    setProductType: (state, { payload }: PayloadAction<string>) => {
      state.productType = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProductsForRestaurantAsync.pending, (state) => {
      state.status = ApiStatus.LOADING;
    });
    builder.addCase(getProductsForRestaurantAsync.fulfilled, (state, { payload }) => {
      state.status = ApiStatus.IDLE;
      state.items = payload.products || [];
      state.total = payload.total || 0;
    });
    builder.addCase(getProductsForRestaurantAsync.rejected, (state) => {
      state.status = ApiStatus.FAILED;
    });
  }
});

export const { clearError, setActiveCategory, setProductType } = productsSlice.actions;

export const selectProductsStatus = ({ products }: RootState): ApiStatus => products.status;
export const selectProducts = ({ products }: RootState): Product[] => products.items;
export const selectProductsCount = ({ products }: RootState): number => products.total;
export const selectProductType = ({ products }: RootState): string => products.productType;
export const selectActiveCategory = ({ products }: RootState): string => products.activeCategory;

// Hardcoding MK sub categories for quick sorting;
// TODO: Move this to backend
const sortedSubTypes: Record<string, number> = {
  // Food
  Доручек: 1,
  Сендвичи: 2,
  Врапови: 3,
  Тостови: 4,
  Салати: 5,
  'Оброци и боулс': 6,
  Десерти: 7,
  // Drinks
  Цедени: 8,
  сокови: 9,
  Смутиња: 10,
  Пијалоци: 11,
  Кафе: 12,
  'Топли напитоци': 13
};

export const selectGroupedProductSubTypes = createSelector([selectProducts], (products) => {
  const groupProducts = products.reduce((r: any, a: any) => {
    r[a.type] = r[a.type] || [];
    if (!r[a.type].includes(a.subType)) {
      r[a.type].push(a.subType);
    }
    return r;
  }, {});
  // sort groupProducts by sortedSubTypes
  Object.keys(groupProducts).forEach((type) => {
    groupProducts[type] = groupProducts[type].sort((a: string, b: string) => {
      return (sortedSubTypes[b] ?? 999) > (sortedSubTypes[a] ?? 999) ? -1 : 1;
    });
  });
  return groupProducts;
});

export const selectGroupedProducts = createSelector(
  [selectProducts, selectActiveCategory, selectProductType],
  (products, category, type) => {
    const groupProducts = products.reduce((r: any, a: any) => {
      r[a.type] = r[a.type] || [];
      r[a.type].push(a);
      return r;
    }, {});
    if (category !== 'all' && groupProducts[type].length > 0) {
      const filteredProducts = groupProducts[type].filter(
        (product: any) => product.subType === category
      );
      groupProducts[type] = sortBy(filteredProducts, 'name');
    }
    return groupProducts;
  }
);

export const selectFilteredProductsByCategory = createSelector(
  [selectProducts, selectActiveCategory],
  (products, category) => {
    return category === 'all'
      ? products
      : products.filter((product) => product.subType === category);
  }
);

export default productsSlice.reducer;
